import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box
} from "@mui/material";
import React from "react";
import JBWDateLabel from "./JBWDateLabel";

const AgreementInfo = ({ agreement, fulldetail = true, showAgreementTable }) => {
  return (
    <>
      {fulldetail ? (
        <TableContainer>
          <Box>
            {showAgreementTable && agreement?.jbwAgreementId && (<Table
              sx={{ minWidth: 550, mb: 4 }}
              size="small"
              aria-label="JBW Agreements table"
            >
              <TableHead sx={{ fontSize: "10px" }}>
                <TableRow>
                  <TableCell align="left">Agreement Id</TableCell>
                  <TableCell align="left">Pdf</TableCell>
                  <TableCell align="left">Warranty Start Date</TableCell>
                  <TableCell align="left">Warranty End Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={agreement.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {agreement?.jbwAgreementId || "N/A"}
                  </TableCell>
                  <TableCell align="left">{agreement?.pdfURL || "N/A"}</TableCell>
                  <TableCell align="left">{agreement?.warrantyStartDate || "N/A"}</TableCell>
                  <TableCell align="left">{agreement?.warrantyEndDate || "N/A"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>)}
            {/* </Box> */}
            {/* <Box> */}
            <Table
              sx={{ minWidth: 550 }}
              size="small"
              aria-label="JBW Agreements table"
            >
              <TableHead sx={{ fontSize: "10px" }}>
                <TableRow>
                  <TableCell>Dealer Id</TableCell>
                  <TableCell align="left">Tenant Id</TableCell>
                  <TableCell align="left">Po Number</TableCell>
                  <TableCell align="left">Job Number</TableCell>
                  <TableCell align="left">Product Code</TableCell>
                  <TableCell align="left">Customer Name</TableCell>
                  <TableCell align="left">Phone & Email</TableCell>
                  <TableCell align="left">Address</TableCell>
                  <TableCell align="left">Purchase Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={agreement.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {agreement?.dealerId}
                  </TableCell>
                  <TableCell align="left">{agreement?.tenantId}</TableCell>
                  <TableCell align="left">{agreement?.poNumber}</TableCell>
                  <TableCell align="left">{agreement?.jobNumber}</TableCell>
                  <TableCell align="left">{agreement?.productCode}</TableCell>
                  <TableCell align="left">{agreement?.customerName}</TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="subtitl1"
                      component="span"
                      sx={{
                        wordWrap: "break-word",
                      }}
                      whiteSpace="normal"
                    >
                      {agreement?.phoneNumber}
                    </Typography>
                    <br />
                    <Typography
                      variant="subtitl1"
                      component="span"
                      sx={{
                        wordWrap: "break-word",
                      }}
                      whiteSpace="normal"
                    >
                      {agreement?.emailAddress}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="subtitl1"
                      component="span"
                      sx={{
                        wordWrap: "break-word",
                      }}
                      whiteSpace="normal"
                    >
                      {agreement.location}.
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    {agreement?.startDate && (
                      <JBWDateLabel
                        inputDate={agreement?.startDate}
                        dateFormat="MM/DD/YYYY"
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </TableContainer>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Typography>
              <b>Job ID:</b> {agreement?.jobNumber}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>
              <b>Invoice ID:</b> {agreement?.invoiceId}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              <b>Customer Name:</b> {agreement?.customer}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography>
              <b>Address:</b>
              {agreement?.address ? agreement?.address : ""}.
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AgreementInfo;
