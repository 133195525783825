import React, { useContext, useEffect, useState } from "react";
import { Alert, Grid } from "@mui/material";
import { AuthContext } from "../AuthContext";
import ClaimsGrid from "./ClaimsGrid";
import {
  getClaimsList,
  rejectClaim,
  updateClaimFromSt,
  updateClaimId,
} from "../services/ClaimsServices.js";

const ClaimsList = ({
  dealer,
  showDealer = false,
  displayType,
  dealerId,
  filterAgreementId,
  tenant,
}) => {
  console.log("Claims List displayType : ", displayType);
  console.log(
    `Claim List displayType dealerid:${dealerId}, filteredAgreementId : ${filterAgreementId}`
  );
  const { user } = useContext(AuthContext);
  const [claimList, setClaimList] = useState([]);
  //const [selectedClaimId, setSelectedClaimId] = useState(null);
  //const [syncClaimId, setSyncClaimId] = useState(null);
  //const [optype, setOptype] = useState("");
  const [selectedDealer, setSelectedDealer] = useState(dealer);
  const [disType, setDisType] = useState(displayType);
  //const [selectedDealerId, setSelectedDealerId] = useState(dealerId);
  const [claimListLoading, setClaimListLoading] = useState(false);
  const [claimMessage, setClaimMessage] = useState("");

  const loadClaimsList = (_dealerId) => {
    setClaimListLoading(true);
    getClaimsList(_dealerId)
      .then(
        (res) => {
          console.log("claims List Response :", res);
          const data = res.data;
          console.log("claims List :", data);
          const statusCode = res.status;
          if (statusCode === 200 && data && data.length > 0) {
            setClaimList(data);
          }
          setClaimListLoading(false);
        },
        (err) => {
          setClaimListLoading(false);
          console.log("Unable to load data from claims service", err);
        }
      )
      .catch((err) => {
        setClaimListLoading(false);
        console.error("Get Claims List Internal Server error", err);
      });
  };

  useEffect(() => {
    //const dealerId = selectedDealer ? selectedDealer.id : 0;
    if (disType === "admin") {
      console.log("loading agreement list from admin user");
      loadClaimsList(0);
    } else {
      console.log("display type :", disType);
      if (selectedDealer) {
        loadClaimsList(selectedDealer.dealerId);
      }
    }
  }, [selectedDealer, disType]);

  const handleVerifyClaim = (param) => {
    console.log("verifyClaim", param);
    setClaimMessage("Please wait while claim Verifing");
    updateClaimFromSt(param.jbwAgreementId, param.jbwClaimId)
      .then(
        (res) => {
          console.log("claims update Response :", res);
          const data = res.data;
          console.log("claims update :", data);
          const statusCode = res.status;
          if (statusCode === 200) {
            setClaimMessage("");
            if (disType === "admin") {
              loadClaimsList(0);
            } else {
              if (selectedDealer) {
                loadClaimsList(selectedDealer.dealerId);
              }
            }
          }
        },
        (err) => {
          setClaimMessage("");
          console.log("Unable to load data from claims service", err);
          if (disType === "admin") {
            loadClaimsList(0);
          } else {
            if (selectedDealer) {
              loadClaimsList(selectedDealer.dealerId);
            }
          }
        }
      )
      .catch((err) => {
        setClaimMessage("");
        console.error("Get Claims List Internal Server error", err);
        if (disType === "admin") {
          loadClaimsList(0);
        } else {
          if (selectedDealer) {
            loadClaimsList(selectedDealer.dealerId);
          }
        }
      });
  };

  const handleRejectClaim = (param) => {
    let selectedIds = "";
    if (Array.isArray(param)) {
      selectedIds = param.map(cl => cl.id).join(",")
    } else {
      selectedIds = param.id;
    }
    console.log("RejectClaim", param);
    console.log("selected dealer :", selectedDealer);
    setClaimMessage("Please wait while claim rejecting");
    rejectClaim(0, user.name, selectedIds)
      .then(
        (res) => {
          console.log("claims Reject Response :", res);
          const data = res.data;
          console.log("claims Reject :", data);
          const statusCode = res.status;
          if (statusCode === 200) {
            setClaimMessage("");
            if (disType === "admin") {
              loadClaimsList(0);
            } else {
              if (selectedDealer) {
                loadClaimsList(selectedDealer.dealerId);
              }
            }
          }
        },
        (err) => {
          setClaimMessage("");
          console.log("Unable to load data from claims service", err);
          if (disType === "admin") {
            loadClaimsList(0);
          } else {
            if (selectedDealer) {
              loadClaimsList(selectedDealer.dealerId);
            }
          }
        }
      )
      .catch((err) => {
        setClaimMessage("");
        console.error("Get Claims List Internal Server error", err);
        if (disType === "admin") {
          loadClaimsList(0);
        } else {
          if (selectedDealer) {
            loadClaimsList(selectedDealer.dealerId);
          }
        }
      });
  };

  const handleUpdateClaim = (param) => {
    console.log("claim list update claim", param);
    if (param && param.claimid) {
      setClaimMessage("Please wait while updating the claim");
      updateClaimId(
        param.id,
        param.claimid,
        user.name,
        param.result,
        param.agreementid
      )
        .then(
          (res) => {
            console.log("claims id update Response :", res);
            const data = res.data;
            console.log("claims id update :", data);
            const statusCode = res.status;
            if (statusCode === 200) {
              setClaimMessage("");
              if (disType === "admin") {
                loadClaimsList(0);
              } else {
                if (selectedDealer) {
                  loadClaimsList(selectedDealer.dealerId);
                }
              }
            }
          },
          (err) => {
            setClaimMessage("");
            console.log("Unable to load data from claims service", err);
            if (disType === "admin") {
              loadClaimsList(0);
            } else {
              if (selectedDealer) {
                loadClaimsList(selectedDealer.dealerId);
              }
            }
          }
        )
        .catch((err) => {
          setClaimMessage("");
          console.error("Get Claims List Internal Server error", err);
          if (disType === "admin") {
            loadClaimsList(0);
          } else {
            if (selectedDealer) {
              loadClaimsList(selectedDealer.dealerId);
            }
          }
        });
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {!claimMessage ? (
            <ClaimsGrid
              claimList={claimList}
              showDealer={showDealer}
              showLoading={claimListLoading}
              onVerifyClaim={handleVerifyClaim}
              onRejectClaim={handleRejectClaim}
              onUpdateClaim={handleUpdateClaim}
              tenant={tenant}
            />
          ) : (
            <Alert severity="info">{claimMessage}</Alert>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ClaimsList;
