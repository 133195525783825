import React, { useContext, useEffect } from "react";
import { AuthContext } from "../AuthContext";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { pink, red } from "@mui/material/colors";

const EquipmentsTable = ({
  selectedInvoice,
  showAction = true,
  showCheckboxes = false,
}) => {
  const { ctxStEquipments, setCtxStEquipments } = useContext(AuthContext);
  console.log("EquipmentTable ctxStEquipment :", ctxStEquipments);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [previous, setPrevious] = React.useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [deleteIndex, setDeleteIndex] = React.useState(-1);
  const [openBulkDeleteDialog, setOpenBulkDeleteDialog] = React.useState(false);
  const [isBulkDelete, setIsBulkDelete] = React.useState(false);
  const [error, setError] = React.useState("");

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.mlight,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const handleAddEquipment = () => {
    const id = ctxStEquipments.length + 1;
    const newObj = {
      id: id,
      manufacturer: null,
      modelNo: null,
      serialNumber: null,
      isEditMode: true,
      isNew: true,
      locationId: selectedInvoice?.locationId,
      equipmentType: "new",
    };
    setCtxStEquipments((oldData) => {
      return [...oldData, newObj];
    });
    setPrevious((state) => ({ ...state, [id]: newObj }));
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allIds = ctxStEquipments.map((equipment) => equipment.id);
      setSelectedRows(allIds);
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  const handleDeleteSelected = () => {
    setIsBulkDelete(true); // Set bulk delete to true
    setOpenDeleteDialog(true);
  };

  useEffect(() => {
    if (ctxStEquipments.length === 0) {
      const id = ctxStEquipments.length + 1;
      const newObj = {
        id: id,
        manufacturer: null,
        modelNo: null,
        serialNumber: null,
        isEditMode: true,
        locationId: selectedInvoice?.locationId,
        equipmentType: "new",
      };
      setCtxStEquipments((oldData) => {
        return [newObj];
      });
      setPrevious((state) => ({ ...state, [id]: newObj }));
    }
  }, [ctxStEquipments]);

  const handleDelete = (index) => {
    console.log("index value", index);
    setDeleteIndex(index);
    setIsBulkDelete(false);
    setOpenDeleteDialog(true);
  };

  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious((state) => ({ ...state, [row.id]: row }));
    }
    console.log("On change Previous eq list :", previous);
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    const newRows = ctxStEquipments.map((ctsteq) => {
      if (ctsteq.id === id) {
        return { ...ctsteq, [name]: value };
      }
      return ctsteq;
    });
    setCtxStEquipments(newRows);
  };

  const onToggleEditMode = (id, action) => {
    console.log("ontoggleeditmode ctx : ", ctxStEquipments);
    const eq = ctxStEquipments.filter((row) => row.id === id);
    console.log("ontoggleeditmode  eq: ", eq.manufacturer);

    if (eq.length > 0 && !eq[0].manufacturer && action === "done") {
      setError("Manufacturer is mandatory");
      return; // Exit the function without toggling edit mode
    }

    setError("");

    setCtxStEquipments((state) => {
      return state.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            isEditMode: !row.isEditMode,
          };
        }
        return row;
      });
    });
  };

  const onRevert = (id) => {
    const row = ctxStEquipments.find((row) => row.id === id);
    console.log("reverting id :", id);
    // console.log("Previous eq list :", previous);
    // console.log("Previous ctx eq list :", ctxStEquipments);
    if (row?.isNew) {
      setError("");
      // If it's a new row with no saved data, remove it instead of reverting
      setCtxStEquipments((oldData) => oldData.filter((row) => row.id !== id));
    } else {
      const newRows = ctxStEquipments.map((row) => {
        if (row.id === id) {
          console.log("found row id :", row.id);
          console.log("found previous id ", previous[id]);
          return previous[id] ? previous[id] : row;
        }
        return row;
      });
      //console.log("Edited eq list :", newRows);
      setCtxStEquipments(newRows);
      setPrevious((state) => {
        delete state[id];
        return state;
      });

      onToggleEditMode(id);
    }
  };

  const handleDialogClose = (data) => {
    setOpenDeleteDialog(false);
    console.log("equipment delete index : ", deleteIndex);
    if (data) {
      if (isBulkDelete) {
        // Bulk delete confirmed
        setCtxStEquipments((oldData) =>
          oldData.filter((equipment) => !selectedRows.includes(equipment.id))
        );
        setSelectedRows([]); // Clear selection after deletion
      } else if (deleteIndex !== -1) {
        setCtxStEquipments((olddata) =>
          olddata.filter((f, i) => i !== deleteIndex)
        );
      }
    }
  };

  return (
    <Grid container spacing={1} sx={{ p: 2 }}>
      <DeleteConfirmDialog
        open={openDeleteDialog}
        onClose={handleDialogClose}
      />
      <Grid item xs={6}>
        <Typography component="subtitle1" variant="subtitle1">
          <b>Equipment</b>
        </Typography>
        {error && (
          <span style={{ color: "red", marginLeft: "30px" }}>{error}</span>
        )}
      </Grid>

      <Grid item xs={12}>
        <TableContainer sx={{ maxHeight: 350 }} component={Paper}>
          <Table
            sx={{ minWidth: 550 }}
            size="small"
            aria-label="JBW Agreement Equipment table"
            stickyHeader
          >
            <TableHead sx={{ fontSize: "10px" }}>
              <TableRow>
                {showCheckboxes && (
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length < ctxStEquipments.length
                      }
                      checked={
                        ctxStEquipments.length > 0 &&
                        selectedRows.length === ctxStEquipments.length
                      }
                      onChange={handleSelectAll}
                    />
                  </StyledTableCell>
                )}
                <StyledTableCell align="left">Manufacturer</StyledTableCell>
                <StyledTableCell align="left">Model Number</StyledTableCell>
                <StyledTableCell align="left">Serial Number</StyledTableCell>
                {showAction && (
                  <StyledTableCell align="center">Actions</StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {ctxStEquipments.map((equipment, index) => (
                <TableRow
                  key={equipment.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  {showCheckboxes && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRows.includes(equipment.id)}
                        onChange={() => handleRowSelect(equipment.id)}
                      />
                    </TableCell>
                  )}
                  <CustomTableCell
                    {...{ row: equipment, name: "manufacturer", onChange }}
                  />
                  <CustomTableCell
                    {...{
                      row: equipment,
                      name: "modelNo",
                      onChange,
                    }}
                  />
                  <CustomTableCell
                    {...{ row: equipment, name: "serialNumber", onChange }}
                  />
                  {showAction && (
                    <TableCell align="center" sx={{ width: 60 }}>
                      {equipment.isEditMode ? (
                        <>
                          <IconButton
                            aria-label="done"
                            color="info"
                            onClick={() =>
                              onToggleEditMode(equipment.id, "done")
                            }
                          >
                            <DoneIcon />
                          </IconButton>
                          <IconButton
                            aria-label="revert"
                            color="error"
                            disabled={ctxStEquipments.length === 0}
                            onClick={() => onRevert(equipment.id)}
                          >
                            <NotInterestedIcon />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            color="error"
                            aria-label="delete"
                            onClick={(e) => handleDelete(index)}
                            disabled={selectedRows.length > 0}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <IconButton
                            color="info"
                            aria-label="edit"
                            onClick={(e) => onToggleEditMode(equipment.id)}
                            disabled={selectedRows.length > 0}
                          >
                            <EditIcon />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {showAction && (
            <Box display="flex" justifyContent="flex-end" sx={{ pr: "10px" }}>
              {selectedRows.length > 0 && (
                <IconButton
                  color="error"
                  onClick={handleDeleteSelected}
                  aria-label="bulk delete"
                >
                  <DeleteIcon />
                </IconButton>
              )}
              <IconButton
                color="secondary"
                variant="outlined"
                aria-label="search job"
                sx={{ mr: "4px", fontSize: "17px" }}
                disabled={ctxStEquipments.length === 5 || selectedRows.length > 0}
                onClick={handleAddEquipment}
              >
                <AddBoxIcon sx={{ fontSize: "20px", mr: "3px" }} />
                Add
              </IconButton>
            </Box>
          )}
        </TableContainer>
      </Grid>
    </Grid>
  );
};

const CustomTableCell = ({ row, name, onChange }) => {
  //console.log("customtablecell name:", name);
  //console.log("customtablecell row:", row);
  const { isEditMode } = row;
  return (
    <TableCell align="left" sx={{ width: 130, height: 40 }}>
      {isEditMode ? (
        <Input
          value={row[name]}
          name={name}
          onChange={(e) => onChange(e, row)}
          sx={{ width: 130, height: 40 }}
        />
      ) : (
        row[name]
      )}
    </TableCell>
  );
};

const DeleteConfirmDialog = ({ open = false, onClose, title, message }) => {
  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    onClose(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        component: "div",
      }}
      fullWidth={true}
      maxWidth={"sm"}
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <b>{title ? title : "Equipment Delete"}</b>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              align="center"
              sx={{ fontSize: "18px", color: red[900] }}
            >
              <WarningAmberIcon
                sx={{ fontSize: 50, color: red[900], mb: -1 }}
              />{" "}
              {message ? message : " Are You Sure. Do you want delete the Equipment ?"}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item></Grid>
          <Box sx={{ flexGrow: 1 }} />
          <Grid item>
            <Button onClick={() => onClose(true, title)}>Yes</Button>
            <Button onClick={handleClose}>No</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export { DeleteConfirmDialog };
export default EquipmentsTable;
