import React, { useContext, useEffect, useState } from "react";
import {
  getAgreementDetails,
  createDupilcateAgreement,
} from "../services/agreementServices";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import AgreementDetails from "./AgreementDetails";
import { AuthContext } from "../AuthContext";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { amber, blue } from "@mui/material/colors";

const Defaul_Load_Msg = " Please Wait while Loading Agreement Details ....";
const DuiplicateAgreementCreateDialog = ({
  openAgmDetailsDialog,
  agreementId,
  onCloseAgmDetailsDialog,
}) => {
  const { user, ctxStEquipments, setCtxStEquipments } = useContext(AuthContext);
  const [agreementDetails, setAgreementDetails] = useState(null);
  const [loading, setLoading] = useState(Defaul_Load_Msg);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [disableCancelBtn, setDisableCancelBtn] = useState(false);
  const [saveError, setSaveError] = useState("");
  const [deletedEquipments, setDeletedEquipments] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  console.log("Duplicating agreement id :", agreementId);
  useEffect(() => {
    if (agreementId) {
      setDisableSaveBtn(true);
      setLoading(Defaul_Load_Msg);
      setSaveError("");
      getAgreementDetails(agreementId).then(
        (res) => {
          console.log(res);
          if (res.status === 200) {
            console.table(res.data);
            if (res.data) {
              setDisableSaveBtn(false);
              setDisableCancelBtn(false);
              setAgreementDetails(res.data);
              setCtxStEquipments(res.data.equipments);
              setLoading("");
            } else {
              setLoading("No Agreement Details availiable");
            }
          } else {
            setLoading("Unable to load Agreement Details");
          }
        },
        (err) => {
          setLoading("Error loading agreement details data");
          console.error("Error loading agreement details data");
        }
      );
    }
  }, [agreementId]);

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    setAgreementDetails(null);
    setSaveError("");
    setLoading("");
    setCtxStEquipments([]);
    onCloseAgmDetailsDialog();
  };

  const handleConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirm = (param) => {
    if (param) {
      handleCreate();
    }
    setOpenConfirmDialog(false);
  };

  const handleCreate = () => {
    setDisableSaveBtn(true);
    setDisableCancelBtn(true);
    setSaveError("");
    const tempAgreementDetails = agreementDetails;
    const eqdetails = ctxStEquipments.filter(
      (eq) =>
        eq.manufacturer !== null &&
        eq.manufacturer !== "null" &&
        eq.manufacturer.trim().length > 0
    );
    if (!eqdetails > 0) {
      setSaveError("Please Enter Equipment Details.");
      setDisableCancelBtn(false);
      setDisableSaveBtn(false);
      return;
    }

    setLoading("Please wait while creating Duplicate Agreement");

    //setAgreementDetails(null);
    createDupilcateAgreement(agreementId, {
      agreementdetails: {
        ...tempAgreementDetails,
        equipments: ctxStEquipments,
      },
      createdBy: user.name,
    }).then(
      (res) => {
        if (res.status === 200) {
          console.log("Duplicate Agreement id :", res.data.agreementId);
          setLoading("Duplicate Agreement Created Successfully");
          onCloseAgmDetailsDialog("success");
          setCtxStEquipments([]);
        } else {
          setDisableSaveBtn(false);
          setDisableCancelBtn(false);
          setSaveError("Unable to create Duplicate Agreement");
          setLoading("");
          setAgreementDetails(tempAgreementDetails);
        }
      },
      (err) => {
        setDisableSaveBtn(false);
        setDisableCancelBtn(false);
        setLoading("");
        setAgreementDetails(tempAgreementDetails);
        setSaveError("Unable to create Duplicate Agreement");
      }
    );
  };
  const handleDeletedEquipment = (params) => {
    console.log("deleting agreements called in dialog :", params);
    const equipments = agreementDetails.equipments.filter(
      (eq) => eq.id !== params.id
    );
    setAgreementDetails((oldValue) => ({
      ...oldValue,
      equipments: [...equipments],
    }));
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={openAgmDetailsDialog}
        PaperProps={{
          component: "div",
        }}
        fullWidth={true}
        maxWidth={"lg"}
        disableEscapeKeyDown
      >
        <DialogTitle>Agreement details</DialogTitle>
        <DialogContent dividers>
          {agreementDetails ? (
            <AgreementDetails
              agreementDetails={agreementDetails}
              deletedEquipment={handleDeletedEquipment}
            />
          ) : (
            <Grid container>
              <Grid item xs={12} sx={{ maxHeight: "600px", maxWidth: "600px" }}>
                <Typography
                  color="secondary"
                  variant="caption"
                  align="left"
                  sx={{ fontSize: "14px" }}
                >
                  {loading}
                </Typography>
              </Grid>
            </Grid>
          )}
          {saveError && (
            <Typography
              variant="body"
              sx={{ color: "#f44336", fontSize: "10px" }}
            >
              {saveError}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialog} disabled={disableSaveBtn}>
            Save
          </Button>
          <Button onClick={handleClose} disabled={disableCancelBtn}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog open={openConfirmDialog} onClose={handleCloseConfirm} />
    </>
  );
};

const ConfirmDialog = ({ open = false, onClose }) => {
  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    onClose(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        component: "div",
      }}
      fullWidth={true}
      maxWidth={"sm"}
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <b>Copy Agreement</b>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              align="center"
              sx={{ fontSize: "16px", color: blue[500] }}
            >
              <CopyAllIcon sx={{ fontSize: 50, color: amber[500], mb: -2 }} />{" "}
              Are you sure you want to create a copy of this agreement�
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item></Grid>
          <Box sx={{ flexGrow: 1 }} />
          <Grid item>
            <Button onClick={() => onClose(true)}>Yes</Button>
            <Button onClick={handleClose}>No</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default DuiplicateAgreementCreateDialog;
