import {
  Avatar,
  IconButton,
  styled,
  Box,
  Skeleton,
  Typography,
  Link,
  Tooltip,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Stack,
  InputLabel,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import CustomNoRowsOverlay from "./gridNoRowOverLay";
import EditIcon from "@mui/icons-material/Edit";
import AssignmentLateTwoToneIcon from "@mui/icons-material/AssignmentLateTwoTone";
import AssignmentTurnedInTwoToneIcon from "@mui/icons-material/AssignmentTurnedInTwoTone";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { green, amber } from "@mui/material/colors";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { envConfig } from "../services/configs/envConfig";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import EquipmentsTable, { DeleteConfirmDialog } from "./EquipmentsTable";
import { deleteAgreementEquipments } from "../services/agreementServices";
import { AuthContext } from "../AuthContext";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .super-app-theme--Active": {
    backgroundColor: "#71d68e",
    "&:hover": {
      backgroundColor: "#4ECC72",
    },
  },
  "& .super-app-theme--InActive": {
    backgroundColor: "#e4c553",
    "&:hover": {
      backgroundColor: "#DEB729",
    },
  },
  "& .super-app-theme--dup": {
    backgroundColor: "#FFF8EB",
    "&:hover": {
      backgroundColor: "#FFD485",
    },
  },
}));

const AgreementsGrid = ({
  agreementList,
  showDealer = false,
  openEquimentDialog,
  openEquConfirmDialog,
  openDuplicateAgreementDialog,
  onNewAgreement,
  showLoading = false,
  tenant,
  page,
  setPage,
  onScrolleUp,
}) => {

  const handleEdit = React.useCallback( (param, optype) => () => {
    console.log("Edit clicked ", param.row);
    openEquimentDialog(param.row, optype);
  },[]);

  const handleAdd =  React.useCallback( (param, optype) => () => {
    console.log("Edit clicked ", param.row);
    openEquimentDialog(param.row, optype);
  },[]);

  const handleSubmitAgreement =   React.useCallback( (param) => () => {
    console.log("Submit Agreement clicked ", param.row);
    console.log(page);
    openEquConfirmDialog(param.row);
  },[]);

  const viewAgreement =    React.useCallback( (param) => () => {
    console.log("View Agreement clicked ", param.row);
    console.log(page);
    openEquConfirmDialog(param.row, true);
  },[]);

  const deleteAgreement = React.useCallback( (param) => () => {
    console.log("Delete Agreement clicked ", param.row);
    console.log(page);
    setSelectedAgreementToDelete(param.row);
    setDeleteDialogOpen(true);
  },[]);

  const handleDuplicateAgreement = React.useCallback( (param) => () => {
    console.log("Duplicate Agreement clicked ", param.row);
    openDuplicateAgreementDialog(param.row);
  },[]);

  const handleComment = React.useCallback( (param) => {
    //console.log("Agreement grid handle comment:", param.value);
    if (param.value === "") return;
    if (Boolean(isValidUrl(param.value)))
      return (
        <IconButton
          aria-label="jbandassociates.biz"
          onClick={() => window.open(param.value)}
          color="warning"
        >
          <PictureAsPdfIcon fontSize="medium" />
        </IconButton>
      );
    return param.value;
  },[]);


  const columns = useMemo(() =>[
    {
      field: "id",
      headerName: "",
      width: 45,
      sortable: false,
      renderCell: (params) => {
        const bgColor =
          params.row.jbwAgreementId === null || params.row.jbwAgreementId === ""
            ? "#DEB729"
            : "#4ECC72";
        const iconDisaply =
          params.row.jbwAgreementId === null ||
            params.row.jbwAgreementId === "" ? (
            <AssignmentLateTwoToneIcon />
          ) : (
            <AssignmentTurnedInTwoToneIcon />
          );
        return (
          <Tooltip title={`JB360# :${params.row.id}`}>
            <Avatar sx={{ bgcolor: bgColor, m: 1 }}>{iconDisaply}</Avatar>
          </Tooltip>
        );
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "dealerId",
      headerName: "Dealer ID",
      width: 90,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "jobNumber",
      headerName: "Job ID",
      width: showDealer ? 80 : 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "poNumber",
      headerName: "Invoice #",
      width: showDealer ? 100 : 110,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const poNumber = params.row.poNumber;
        const invoiceId = params.row.invoiceId;
        return (
          <Typography variant="string" component="span">
            <Link className="table-cell-trucate" underline="always">
              {poNumber ? poNumber : invoiceId}
            </Link>
          </Typography>
        );
      },
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: showDealer ? 140 : 160,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "location",
      headerName: "Location",
      width: showDealer ? 200 : 210,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (param) => {
        const location = param.row.location;
        const invoiceId = param.row.invoiceId;
        const jbwAgreementId = param.row.jbwAgreementId;
        const tip = jbwAgreementId ? (
          <div style={{ whiteSpace: "pre-line" }}>
            Agreement: {jbwAgreementId}
          </div>
        ) : (
          <div style={{ whiteSpace: "pre-line" }}>Invoice#: {invoiceId}</div>
        );

        return (
          <>
            {location ? (
              <>
                <Typography
                  variant="subtitl1"
                  component="span"
                  sx={{
                    wordWrap: "break-word",
                  }}
                  whiteSpace="normal"
                >
                  {location}.
                  {param.row.parentAgreementId ? (
                    <Tooltip title={tip}>
                      <InfoRoundedIcon
                        style={{ fill: "#ffc400" }}
                        fontSize="small"
                      />
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </Typography>
              </>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "productCode",
      headerName: "Plan Id",
      width: showDealer ? 120 : 140,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "jbwAgreementId",
      headerName: "JBW Agreement Id",
      width: 140,
      sortable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "comments",
      headerName: "Comments",
      width: showDealer ? 180 : 200,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (param) => {
        return handleComment(param);
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      cellClassName: "actions",
      width: 150,
      headerAlign: "center",
      align: "right",
      getActions: (params) => {
        //console.log("params", params.row);
        const missingEqu = params.row.missingEquipment;
        const readyForJbw = params.row.isReadyForJBW;
        const jbqAId = params.row.jbwAgreementId;
        let cellItems = [];
        //isreadyfor jwd = 0
        if (
          missingEqu === 0 &&
          (jbqAId === null || jbqAId === "") &&
          readyForJbw === 0
        ) {
          cellItems.push(
            <GridActionsCellItem
              icon={<EditIcon fontSize="medium" />}
              label="Edit"
              className="textPrimary"
              onClick={handleEdit(params, "update")}
              color="secondary"
              title="Edit Equipment"
            />
          );
        }
        if (missingEqu === 1 && readyForJbw === 0) {
          cellItems.push(
            <GridActionsCellItem
              icon={<AddToQueueIcon fontSize="medium" />}
              label="Add"
              className="textPrimary"
              onClick={handleAdd(params, "create")}
              color="primary"
              title="Add Equipment"
            />
          );
        }
        if (readyForJbw === 1 && (jbqAId === null || jbqAId === "")) {
          cellItems.push(
            <GridActionsCellItem
              icon={<PostAddIcon fontSize="medium" />}
              label="Submit Agreement"
              className="textPrimary"
              onClick={handleSubmitAgreement(params)}
              color="green"
              sx={{ color: green[500] }}
              title="Submit Agreement"
            />
          );
        }
        cellItems.push(
          <GridActionsCellItem
            icon={<ContentCopyIcon fontSize="medium" />}
            label="Duplicate Agreement"
            className="textPrimary"
            onClick={handleDuplicateAgreement(params)}
            color="amber"
            sx={{ color: amber[500] }}
            title="Duplicate Agreement"
            showInMenu
          />
        );
        if (jbqAId === null || jbqAId === "") {
          cellItems.push(
            <GridActionsCellItem
              icon={<DeleteIcon fontSize="medium" />}
              label="Delete Agreement"
              className="textPrimary"
              onClick={deleteAgreement(params)}
              color="error"
              title="Delete Agreement"
              showInMenu
            />
          );
        }

        if(jbqAId){
        cellItems.push(
          <GridActionsCellItem
            icon={<VisibilityIcon fontSize="medium" />}
            label="View Agreement"
            className="textPrimary"
            onClick={viewAgreement(params)}
            color="green"
            sx={{ color: green[500] }}
            title="View Agreement"
          />
        );
      }
        return cellItems;
      },
      sortable: false,
    },
  ],[handleEdit,handleAdd,handleSubmitAgreement,deleteAgreement,handleDuplicateAgreement,viewAgreement,handleComment]);


  const handlePageChange = (newPage) => {
    setPage(newPage); // Update page state
    onScrolleUp();
  };

  // if (showDealer) {
  //   columns.splice(1, 0, {
  //     field: "dealerId",
  //     headerName: "Dealer ID",
  //     width: 90,
  //     sortable: false,
  //     headerAlign: "center",
  //     align: "center",
  //   });
  // }
 

  const handleDeleteAgreement = () => {
    console.log("Delete confirmed for agreement: ", selectedAgreementToDelete);
    // Add logic here to delete the agreement (e.g., API call)
    setDeleteDialogOpen(false); // Close dialog after confirming delete
  };



  const isValidUrl = (urlString) => {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };



  const [agreementsList, setAgreementsList] = useState([]);
  const [filterStatus, setFilterStatus] = useState("Pending");
  const [pageSize, setPageSize] = useState(25);
  const statuses = ["Pending", "Completed", "All"];
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedAgreementToDelete, setSelectedAgreementToDelete] =
    useState(null);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    // setAgreementsList(agreementList);
    // if (tenant) {
    //   setAgreementsList((oldvle) => {
    //     return agreementList.filter((al) => al.tenantId === tenant);
    //   });
    // }
    let filteredList = agreementList;

    if (tenant) {
      filteredList = agreementList.filter((al) => al.tenantId === tenant);
    }

    if (filterStatus === "Pending") {
      // Filter agreements with jbwAgreementId as null
      filteredList = filteredList.filter(
        (agreement) => !agreement.jbwAgreementId
      );
    } else if (filterStatus === "Completed") {
      // Filter agreements with jbwAgreementId not null
      filteredList = filteredList.filter(
        (agreement) => !!agreement.jbwAgreementId
      );
    }
    setAgreementsList(filteredList);
    // setPage(0);
  }, [filterStatus, tenant, agreementList]);

  const handleCellClick = (params) => {
    console.log("cell click event :", params);
    const columnName = params.field;
    const cellValue = params.value;
    console.log(`Selected column : ${columnName} and cell value ${cellValue}`);
    if (columnName === "poNumber") {
      const invoiceUrl = `${envConfig.STUrl}#/EditInvoice/${params.row.invoiceId}`;
      console.log("invoice st url :", invoiceUrl);
      window.open(invoiceUrl, "_blank");
    }
  };

  const handleFilterChange = (event) => {
    setFilterStatus(event.target.value);
    setPage(0);
  };

  const handleStatusChange = (status) => {
    setFilterStatus(status);
    setPage(0);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value); // Update page size
    setPage(0); // Reset to the first page when page size changes
  };

  const QuickSearchToolbar = () => {
    const handleSearchFocus = () => {
      if (filterStatus != "All") {
        setFilterStatus("All");
      }
    }
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          p: 1,
        }}
      >
        {/* Left-aligned Quick Filter */}
        <GridToolbarQuickFilter onFocus={handleSearchFocus} />

        {/* Center-aligned Section: Radio Buttons or Buttons */}

        {/* Center-aligned Radio Buttons */}
        <FormControl component="fieldset">
          <RadioGroup
            row
            value={filterStatus}
            onChange={handleFilterChange}
            aria-label="Status Filter"
            name="status-filter"
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <FormControlLabel value="Pending" control={<Radio />} label="Pending" />
            <FormControlLabel value="Completed" control={<Radio />} label="Completed" />
            <FormControlLabel value="All" control={<Radio />} label="All" />
          </RadioGroup>
        </FormControl>

        {!showDealer && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ borderRadius: "50px" }}
              onClick={handleNewAgreement}
            >
              New Agreement
            </Button>
          </Stack>
        )}

        {/* Center-aligned Buttons */}
        {/* <Box sx={{ display: "flex", gap: 1, marginLeft: 2, justifyContent: 'center' }}>
          {statuses.map((status) => (
            <Button
              key={status}
              variant={filterStatus === status ? "contained" : "outlined"}
              onClick={() => handleStatusChange(status)}
              sx={{
                textTransform: "capitalize",
                borderRadius: "8px",
              }}
            >
              {status}
            </Button>
          ))}
        </Box> */}

        {/* Right-aligned Page Size Dropdown */}
        <Stack direction="row" alignItems="center">
          <Typography variant="subtitle1">Page Size:</Typography>
          <FormControl sx={{ minWidth: 75, marginLeft: 1 }}>
            <Select
              labelId="page-size-label"
              value={pageSize}
              onChange={handlePageSizeChange}
              sx={{
                border: "none", // Removes the border
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Removes the border for outlined style
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Prevents border on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Prevents border when focused
                },
              }}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </GridToolbarContainer>
    );
  };

  const handleNewAgreement = () => {
    console.log("New Agreement clicked");
    onNewAgreement();
  };

  const handleDialogClose = (data) => {
    setDeleteDialogOpen(false);
    console.log("equipment delete index : ", data);
    if (data) {
      setAgreementsList((prevList) =>
        prevList.map((agreement) =>
          agreement.id === selectedAgreementToDelete.id
            ? { ...agreement, isDeleted: 1 }
            : agreement
        )
      );
      deleteAgreementEquipments(selectedAgreementToDelete.id, user.name).then(
        (res) => {
          const statusCode = res.status;
          const data = res.data;
          console.log("sync result :", data);
          if (statusCode === 200) {
            setAgreementsList(
              agreementsList.filter(
                (equipment) => equipment.id !== selectedAgreementToDelete.id
              )
            );
          } else {
          }
        },
        (err) => {
          console.log("Equipment Submit error :", err);
        }
      );
    }
  };

  return (
    <>
      {showLoading === true ? (
        <Box sx={{ height: "100px" }}>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Box>
      ) : (
        <>
          <DeleteConfirmDialog
            open={deleteDialogOpen}
            onClose={handleDialogClose}
          />
          <StyledDataGrid
            rows={agreementsList}
            getRowId={(row) => row.id}
            columns={columns}
            onCellClick={handleCellClick}
            autoHeight={false}
            getRowHeight={() => "auto"}
            pageSizeOptions={[25, 50, 100]}
            paginationModel={{ page, pageSize }}
            columnVisibilityModel={{
              dealerId: showDealer,
            }}
            onPaginationModelChange={(model) => {
              handlePageChange(model.page);
              setPageSize(model.pageSize);
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            // getRowClassName={(params) => {
            //   return params.row.parentAgreementId === null
            //     ? ""
            //     : `super-app-theme--dup`;
            // }}
            disableColumnFilter={true}
            disableColumnMenu={true}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              toolbar: QuickSearchToolbar,
            }}
            sx={{
              "& .muidatagrid-colcelltitle ": {
                display: "block",
                textAlign: "center",
                width: "100%",
              },
              "& .MuiTablePagination-toolbar .MuiTablePagination-selectLabel": {
                display: "none", // Hides the "Rows per page" label
              },
              "& .MuiTablePagination-toolbar .MuiInputBase-root": {
                display: "none", // Hides the dropdown
              },
              // height: 490,
              // "& .MuiDataGrid-virtualScroller": {
              //   overflowY: "auto", // Enable vertical scrolling
              // },
              // "& .MuiDataGrid-root": {
              //   maxHeight: "400px", // Set max height for the entire grid
              // },
            }}
          />
        </>
      )}
    </>
  );
};

export default AgreementsGrid;
