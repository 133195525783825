import axios from "./configs/axiosConfig";

export const getClaimsList = async (dealerId) => {
  const url = `claims/${dealerId}`;
  return await axios.get(url);
};

export const rejectClaim = async (id, user, claimIds) => {
  const url = `claims/reject/${id}?uid=${user}&claimids=${claimIds}`;
  return await axios.patch(url);
};

export const updateClaimId = async (id, claimid, user, status, agreementId) => {
  const url = `claims/claimidupdate/${id}?uid=${user}&claimid=${claimid}&status=${status}&agreementid=${agreementId}`;
  return await axios.patch(url);
};

export const updateClaimFromSt = async (agreementid, claimid) => {
  const url = `/claims/stupdate`;
  return await axios.patch(url, { agreementid, claimid });
};
