import {
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import JBWDateLabel from "./JBWDateLabel";
import EquipmentsTable from "./EquipmentsTable";
import AgreementInfo from "./AgreementInfo";

const AgreementDetails = ({ agreementDetails, deletedEquipment }) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  const agreementDetailTable = (agreement) => {
    return (
      <TableContainer>
        <Table
          sx={{ minWidth: 550 }}
          size="small"
          aria-label="JBW Agreements table"
        >
          <TableHead sx={{ fontSize: "10px" }}>
            <TableRow>
              <TableCell>Dealer Id</TableCell>
              <TableCell align="left">Tenant Id</TableCell>
              <TableCell align="left">Po Number</TableCell>
              <TableCell align="left">Job Number</TableCell>
              <TableCell align="left">Product Code</TableCell>
              <TableCell align="left">Customer Name</TableCell>
              <TableCell align="left">Phone & Email</TableCell>
              <TableCell align="left">Address</TableCell>
              <TableCell align="left">Purchase Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={agreement.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell component="th" scope="row">
                {agreement?.dealerId}
              </TableCell>
              <TableCell align="left">{agreement?.tenantId}</TableCell>
              <TableCell align="left">{agreement?.poNumber}</TableCell>
              <TableCell align="left">{agreement?.jobNumber}</TableCell>
              <TableCell align="left">{agreement?.productCode}</TableCell>
              <TableCell align="left">{agreement?.customerName}</TableCell>
              <TableCell align="left">
                <Typography
                  variant="subtitl1"
                  component="span"
                  sx={{
                    wordWrap: "break-word",
                  }}
                  whiteSpace="normal"
                >
                  {agreement?.phoneNumber}
                </Typography>
                <br />
                <Typography
                  variant="subtitl1"
                  component="span"
                  sx={{
                    wordWrap: "break-word",
                  }}
                  whiteSpace="normal"
                >
                  {agreement?.emailAddress}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="subtitl1"
                  component="span"
                  sx={{
                    wordWrap: "break-word",
                  }}
                  whiteSpace="normal"
                >
                  {agreement.location}.
                </Typography>
              </TableCell>

              <TableCell align="left">
                {agreement?.startDate && (
                  <JBWDateLabel
                    inputDate={agreement?.startDate}
                    dateFormat="MM/DD/YYYY"
                  />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AgreementInfo agreement={agreementDetails} />
      </Grid>

      <Grid item xs={12}>
        <EquipmentsTable showCheckboxes={true} />
      </Grid>
    </Grid>
  );
};

export default AgreementDetails;
