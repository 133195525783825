import { Box, Container, Grid } from "@mui/material";
import TransmissionDetailsList from "../components/TransmissionDetailsList";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthContext";

const TransactionLogs = () => {
    const { dealer, group } = useContext(AuthContext);
    const [selectedDealer, setSelectedDealer] = useState(null);
    useEffect(() => {
        setSelectedDealer((values) => JSON.parse(localStorage.getItem("dealer")));
      }, []);
      useEffect(() => {
        setSelectedDealer((values) => JSON.parse(localStorage.getItem("dealer")));
      }, [dealer]);
    return (
        <Box component="main">
            <Container maxWidth="xl">
            <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={12}>
                {group !== "jbw-dealer" && 
                <TransmissionDetailsList
                  showDealer={true}
                  displayType="admin"
                />
            }
             {group === "jbw-dealer" && 
                <TransmissionDetailsList
                displayType="dealers"
                dealer={selectedDealer}
              />
            }
                             
              
            
            </Grid>
            </Grid>

            </Container>
        </Box>
    );
};

export default TransactionLogs