import {
  Autocomplete,
  Box,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import {
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  searchInvoice,
  createAgreementFromInvoice,
  getStEquipmentsForTenant,
} from "../services/agreementServices";
import { throttle } from "lodash";
import TblProductsSearch from "./TblProductsSearch";
import { AuthContext } from "../AuthContext";
import EquipmentsTable from "./EquipmentsTable";
import AgreementInfo from "./AgreementInfo";

const NewAgreementDialog = ({
  open,
  onClose,
  dealerId,
  onCreateDuplicateAgreement,
  tenant,
}) => {
  const { user, ctxStEquipments, setCtxStEquipments } = useContext(AuthContext);
  console.log("CtxStEquipment : ", ctxStEquipments.length > 0);
  const [seletedTenant,setSelectedTenant] = useState(tenant);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [disableCancelBtn, setDisableCancelBtn] = useState(false);
  const [typeValue, setTypeValue] = useState("job");
  const [searchKey, setSearchKey] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [agrProductCode, setAgreProductCode] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [infoMsg, setInfoMsg] = useState({
    msg: "Please enter job number and click on search button",
    colorCode: "#6EC207",
  });
  const [openEqiGrid, setEquiGrid] = useState(false);
  const [loadingEqmtData, setLoadingEqmtData] = useState(false);
  const [stEquipments, setStEquipments] = useState([]);

  const handleInvoiceJobSearch = (e) => {
    console.log("seletect tenant in search invoice",seletedTenant);
    setErrorMsg("");
    setSearchResults(null);
    setEquiGrid(false);
    e.preventDefault();
    setInfoMsg({
      msg: "Please wait while loading invoice data",
      colorCode: "#FCDE70",
    });
    console.log("searchType", typeValue);
    console.log("dealerId", dealerId);
    console.log("searchKey", searchKey);
    searchInvoice(dealerId, typeValue, searchKey,seletedTenant).then((res) => {
      console.log("res", res);
      if (res.data.invoiceData && res.data.invoiceData.length > 0) {
        setInfoMsg(null);
        setSearchResults(res.data.invoiceData[0]);
      } else {
        setInfoMsg({ msg: "No Invoice Details Found", colorCode: "#B8001F" });
      }
    });
  };
  const handleSearchCustomer = (data) => {
    setErrorMsg("");
    console.log("customer data", data);
    setInfoMsg(null);
    setSearchResults(data);
  };

  const handleProductSelected = (param) => {
    setErrorMsg("");
    console.log("selected product :", param);
    setInfoMsg(null);
    setAgreProductCode({
      productId: param.productId,
      productCode: param.productCode,
    });
  };

  const handleCreateAgreement = () => {
    setErrorMsg("");
    console.log("create agreement : ", {
      searchResults,
      product: agrProductCode,
      agreementequipment: ctxStEquipments,
    });
    setDisableCancelBtn(true);
    setDisableSaveBtn(true);
    if (
      searchResults &&
      agrProductCode &&
      ctxStEquipments &&
      ctxStEquipments.length > 0
    ) {
      setErrorMsg("");
      const eqdetails = ctxStEquipments.filter(
        (eq) =>
          eq.manufacturer !== null &&
          eq.manufacturer !== "null" &&
          eq.manufacturer.trim().length > 0
      );
      if (!eqdetails > 0) {
        setErrorMsg("Please Enter Equipment Details.");
        setDisableCancelBtn(false);
        setDisableSaveBtn(false);
        return;
      }
      const data = {
        invoiceid: searchResults.invoiceId,
        productcode: agrProductCode.productCode,
        createdBy: user.name,
        tenantid: searchResults.tenantId,
        agreementequipment: ctxStEquipments,
        missingequipment: searchResults.missingEquipment,
      };
      console.log("create new agreement request data : ", data);
      createAgreementFromInvoice(dealerId, data).then(
        (res) => {
          console.log(res);
          if (res.status === 200) {
            console.log("create agreement success", res.data);
            resetDig();
            onClose("success");
          } else {
            setDisableCancelBtn(false);
            setDisableSaveBtn(false);
            console.log("unable to create agreement");
            setErrorMsg("unable to create agreement", res.status);
          }
        },
        (error) => {
          setDisableCancelBtn(false);
          setDisableSaveBtn(false);
          console.log("Error in create agreement");
          setErrorMsg("Error in create agreement", error);
        }
      );
    } else {
      setDisableCancelBtn(false);
      setDisableSaveBtn(true);
      if (!searchResults) {
        console.log("Please select invoice");
        setErrorMsg("Please select invoice");
      } else if (!agrProductCode) {
        console.log("Please select  Plan Id");
        setErrorMsg("Please select Plan Id");
      } else if (!ctxStEquipments.length > 0) {
        console.log("Please Add Atleast One AgreementEquipment");
        setErrorMsg("Please Add Atleast One AgreementEquipment");
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    resetDig();
    onClose();
  };

  const resetDig = () => {
    setInfoMsg({
      msg: "Please enter job number and click on search button",
      colorCode: "#6EC207",
    });
    setDisableSaveBtn(false);
    setDisableCancelBtn(false);
    setSearchResults(null);
    setSearchKey("");
    setTypeValue("job");
    setAgreProductCode(null);
    setLoadingEqmtData(false);
    setEquiGrid(false);
    setCtxStEquipments([]);
    setErrorMsg("");
  };

  const handleTypeChange = (data) => {
    setErrorMsg("");
    setSearchKey("");
    setSearchResults(null);
    setAgreProductCode(null);
    setTypeValue(data);
    setEquiGrid(false);
    if (data === "job") {
      setInfoMsg({
        msg: "Please enter job number and click on search button",
        colorCode: "#6EC207",
      });
    }
    if (data === "customer") {
      setInfoMsg({ msg: "Please enter customer name", colorCode: "#6EC207" });
    }
    if (data === "location") {
      setInfoMsg({ msg: "Please enter Address", colorCode: "#6EC207" });
    }
  };

  const handleCreateDuplicateAgreement = () => {
    const agreementId = searchResults.agreementId;
    resetDig();
    onCreateDuplicateAgreement({ id: agreementId, ctype: "newaggclose" });
  };

  const handleOpentEquipmentsGrid = (e) => {
    e.preventDefault();
    setLoadingEqmtData(true);
    const invoiceObj = {
      invoiceid: searchResults.invoiceId,
      tenantid: searchResults.tenantId,
      locationid: searchResults.locationId,
      clientid: searchResults.clientId,
      clientsecret: searchResults.clientSecret,
      invoiceitemids: searchResults.invoiceitemids
        ? searchResults.invoiceitemids.split(",")
        : "[]",
    };
    getStEquipmentsForTenant(
      searchResults.dealerId,
      encodeURIComponent(JSON.stringify(invoiceObj))
    ).then(
      (res) => {
        if (res.data.response) {
          let eqipments = [];
          if (res.data.data.length > 0) {
            eqipments = res.data.data.map((eq) => {
              return { ...eq, isEditMode: false };
            });
            console.log("equipments with editingmode :", eqipments);
          }
          setCtxStEquipments(eqipments);
          setLoadingEqmtData(false);
          setEquiGrid(true);
        } else {
          setLoadingEqmtData(false);
          setInfoMsg({ msg: res.data.msg, colorCode: "#B8001F" });
        }
      },
      (error) => {
        setInfoMsg({ msg: error, colorCode: "#B8001F" });
      }
    );
  };

  const handleAddEquipmentsGrid = () => {
    setCtxStEquipments([]);
    setEquiGrid(true);
  };

  useEffect(() => {
    if (ctxStEquipments.length > 0 && searchResults && agrProductCode) {
      setDisableSaveBtn(false);
    }
  }, [searchResults, agrProductCode, ctxStEquipments]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        component: "div",
      }}
      fullWidth={true}
      maxWidth={"xl"}
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <b>Create Agreement</b>
      </DialogTitle>
      <DialogContent dividers>
        {!seletedTenant ? (<Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              align="center"
              sx={{ fontSize: "16px", color: "#B8001F" }}
            >
              Please select Tenant from Tenant Table
            </Typography>
          </Grid>
        </Grid>):(
        <Grid container spacing={2}>
          {/* <Grid item xs={4}>
            <FormControl>
              <FormLabel>Search Type</FormLabel>
              <RadioGroup
                row
                defaultValue={"job"}
                name="search-type-row-radio-buttons-group"
                value={typeValue}
                onChange={(event) => handleTypeChange(event.target.value)}
              >
                <FormControlLabel
                  value="job"
                  control={<Radio />}
                  label="Job#"
                />
                <FormControlLabel
                  value="customer"
                  control={<Radio />}
                  label="Customer Name"
                />
                <FormControlLabel
                  value="location"
                  control={<Radio />}
                  label="Address"
                />
              </RadioGroup>
            </FormControl>
          </Grid> */}
          <Grid item xs={4}>
            {typeValue === "job" ? (
              <>
                <TextField
                  id="standard-basic"
                  label="Job Search"
                  variant="standard"
                  sx={{ width: "40%" }}
                  value={searchKey}
                  inputProps={{ autoComplete: "off" }}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                />
                <IconButton
                  color="secondary"
                  variant="outlined"
                  aria-label="search job"
                  sx={{ marginTop: "15px" }}
                  onClick={handleInvoiceJobSearch}
                >
                  <SearchIcon sx={{ fontSize: "25px" }} />
                </IconButton>
              </>
            ) : typeValue === "customer" ? (
              <TblInvoiceCustomerSearch
                searchValue={searchKey}
                onInvoiceSelected={handleSearchCustomer}
                searchType={typeValue}
                dealerId={dealerId}
              />
            ) : (
              <TblInvoiceLocationSearch
                searchValue={searchKey}
                onInvoiceSelected={handleSearchCustomer}
                searchType={typeValue}
                dealerId={dealerId}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <TblProductsSearch
              searchValue={agrProductCode?.productCode}
              onProductSelected={handleProductSelected}
            />
          </Grid>
          {searchResults && searchResults?.isAgreementCreated === 0 && (
            <Grid item xs={12}>
              <AgreementInfo agreement={searchResults} fulldetail={false} />
            </Grid>
          )}
          {openEqiGrid && (
            <Grid item xs={12}>
              <EquipmentsTable selectedInvoice={searchResults} />
            </Grid>
          )}
          <Grid item xs={12}>
            {searchResults && searchResults?.isAgreementCreated === 1 && (
              <Typography
                component="subtitle1"
                variant="subtitle1"
                color="#B8001F"
              >
                Agreement is already created,
                <IconButton
                  color="secondary"
                  variant="outlined"
                  aria-label="copy agreement"
                  sx={{ marginTop: "0px", fontSize: "17px" }}
                  onClick={handleCreateDuplicateAgreement}
                >
                  click here
                  <ContentCopyIcon sx={{ fontSize: "20px", ml: "3px" }} />
                </IconButton>
                to create duplicate agreement
              </Typography>
            )}
            {infoMsg && (
              <Typography
                component="subtitle1"
                variant="subtitle1"
                color={infoMsg.colorCode}
              >
                {infoMsg.msg}
              </Typography>
            )}
            {searchResults?.isAgreementCreated !== 1 &&
              searchResults?.missingEquipment === 0 &&
              !openEqiGrid &&
              !loadingEqmtData && (
                <Typography
                  component="subtitle1"
                  variant="subtitle1"
                  color="#6EC207"
                >
                  Equipment Found for the Invoice{" "}
                  <Button color="warning" onClick={handleOpentEquipmentsGrid}>
                    Click Here
                  </Button>
                  to get details from Service Titan
                </Typography>
              )}
            {searchResults?.isAgreementCreated !== 1 &&
              searchResults?.missingEquipment === 1 &&
              !openEqiGrid && (
                <Button
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={handleAddEquipmentsGrid}
                >
                  Add Equipment
                </Button>
              )}
            {loadingEqmtData && (
              <Typography
                component="subtitle1"
                variant="subtitle1"
                color="#6EC207"
              >
                Please wait while Loading Equiments From Service Titans
              </Typography>
            )}
          </Grid>
        </Grid>
        ) }
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item>
            {errorMsg && (
              <Typography sx={{ color: "red" }}>{errorMsg}</Typography>
            )}
          </Grid>
          <Box sx={{ flexGrow: 1 }} />
          <Grid item>
          {seletedTenant && (
            <Button onClick={handleCreateAgreement} disabled={disableSaveBtn}>
              Save
            </Button>)}
            <Button onClick={handleClose} disabled={disableCancelBtn}>
              Cancel
            </Button>
          </Grid>
          
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const TblInvoiceCustomerSearch = ({
  searchValue,
  onInvoiceSelected,
  searchType,
  dealerId,
}) => {
  //console.log("search value:", searchValue);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  useEffect(() => {
    setValue({ customer: searchValue });
  }, [searchValue]);
  //console.log("value :", value);
  const fetch = React.useMemo(
    () =>
      throttle((request) => {
        if (request?.input.length > 2) {
          const getData = searchInvoice(dealerId, searchType, request?.input);
          getData.then((res) => {
            console.log("customer data memo :", res.data.invoiceData);
            setOptions(res.data.invoiceData);
            return res.data.invoiceData;
          });
        }
      }, 200),
    []
  );

  const onValueSelect = (data) => {
    console.log("selected Data", data);
    onInvoiceSelected(data);
  };

  React.useEffect(() => {
    let active = true;
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="tbl-Invoice-customer-search"
      freeSolo
      sx={{ width: 250, borderRadius: 50 }}
      getOptionLabel={(option) => {
        return `${option.customer ? option.customer : ""}`;
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      loading={true}
      loadingText={"Loading Customer's..."}
      isOptionEqualToValue={(option, value) =>
        option.customer === value.customer
      }
      noOptionsText={
        "No Invoice Found with given Customer Name , Please enter Customer Name "
      }
      onChange={(event, newValue) => {
        console.log("newValue", newValue);
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue ? newValue : { customer: searchValue });
        onValueSelect(newValue ? newValue : { customer: searchValue });
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ fontSize: 12 }}
          variant="standard"
          label="Customer Search"
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" sx={{ fontSize: 16 }} {...props} key={option.id}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0.5}
          >
            <p>Customer : {option.customer}</p>
          </Stack>
        </Box>
      )}
    />
  );
};
const TblInvoiceLocationSearch = ({
  searchValue,
  onInvoiceSelected,
  searchType,
  dealerId,
}) => {
  //console.log("search value:", searchValue);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  useEffect(() => {
    setValue({ address: searchValue });
  }, [searchValue]);
  //console.log("value :", value);
  const fetch = React.useMemo(
    () =>
      throttle((request) => {
        if (request?.input.length > 2) {
          const getData = searchInvoice(dealerId, searchType, request?.input);
          getData.then((res) => {
            console.log("Address data memo :", res.data.invoiceData);
            setOptions(res.data.invoiceData);
            return res.data.invoiceData;
          });
        }
      }, 200),
    []
  );

  const onValueSelect = (data) => {
    console.log("selected Data", data);
    onInvoiceSelected(data);
  };

  React.useEffect(() => {
    let active = true;
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="tbl-Invoice-location-search"
      freeSolo
      sx={{ width: 250, borderRadius: 50 }}
      getOptionLabel={(option) => {
        return `${option.address ? option.address : ""}`;
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      loading={true}
      loadingText={"Loading Address..."}
      isOptionEqualToValue={(option, value) => option.address === value.address}
      noOptionsText={
        "No Invoice Found with given Address , Please enter Address."
      }
      onChange={(event, newValue) => {
        console.log("newValue", newValue);
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue ? newValue : { address: searchValue });
        onValueSelect(newValue ? newValue : { address: searchValue });
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ fontSize: 12 }}
          variant="standard"
          label="Address Search"
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" sx={{ fontSize: 16 }} {...props} key={option.id}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0.5}
          >
            <p>Address : {option.address}</p>
          </Stack>
        </Box>
      )}
    />
  );
};

export default NewAgreementDialog;
